import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mc-color-picker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chrome = _resolveComponent("Chrome")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Chrome, {
      modelValue: _ctx.localColor,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localColor) = $event)),
        _ctx.onColorChange
      ]
    }, null, 8, ["modelValue", "onUpdate:modelValue"])
  ]))
}