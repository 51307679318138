
import { defineComponent } from "vue";
import { Chrome } from "@ckpack/vue-color";
export default defineComponent({
  name: "ColorPicker",
  components: {
    Chrome,
  },
  props: {
    color: {
      type: String,
      required: true,
      default: () => "#000",
    },
  },
  data() {
    return {
      localColor: "",
    };
  },
  mounted() {
    this.localColor = this.color;
  },
  methods: {
    onColorChange(val: any) {
      this.$emit("update:modelValue", val);
    },
  },
});
